/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    var mainMenuOpen = false,
        secondaryMenuOpen = false,
        searchOpen = false,
        mainMenuHeight = 70,
        $mainMenu = $('#main-menu'),
        $secondaryMenuContainer = $('.in-page-nav-container'),
        $secondaryMenu = $('#in-page-navbar'),
        $search = $('#searchbar');

    function initHashLinks() {

        $('a[href*="#"]:not([href="#"]):not([noscroll])').click(function (e) {

            if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                var $target = $(this.hash), target = this.hash;
                $target = $target.length ? $target : $('[name=' + this.hash.slice(1) + ']');

                if ($target.length) {

                    e.preventDefault();

                    var scrollTop = $target.offset().top;

                    if (!$('body').hasClass('fixed')) {
                        //scrollTop -= (mainMenuHeight + secondaryMenuHeight + 50);
                    }

                    $mainMenu.collapse('hide');
                    $secondaryMenu.collapse('hide');

                    $('html, body').stop().animate({
                        scrollTop: scrollTop
                    }, 900, 'swing', function () {
                        location.hash = target;
                    });

                }
            }

        });

    }

    function toggleSiteHeader() {
        if ($(window).scrollTop() || mainMenuOpen || searchOpen) {
            $('#site-header').addClass('slim');
        } else {
            $('#site-header').removeClass('slim');
        }
    }

    function initMainMenu() {

        toggleSiteHeader();

        $(window).on('scroll touchstart touchmove touchend scrollend gesturechange', toggleSiteHeader);

        $mainMenu.on('show.bs.collapse', function () {
            mainMenuOpen = true;
            $search.collapse('hide');
            $secondaryMenu.collapse('hide');
            toggleSiteHeader();
        });

        $search.on('show.bs.collapse', function () {
            searchOpen = true;
            $mainMenu.collapse('hide');
            $secondaryMenu.collapse('hide');
            toggleSiteHeader();
        });

        $secondaryMenu.on('show.bs.collapse', function () {
            secondaryMenuOpen = true;
            $mainMenu.collapse('hide');
            $search.collapse('hide');
        });

        $mainMenu.on('hide.bs.collapse', function () {
            mainMenuOpen = false;
            toggleSiteHeader();
        });

        $search.on('hide.bs.collapse', function () {
            searchOpen = false;
            toggleSiteHeader();
        });

        $secondaryMenu.on('hide.bs.collapse', function () {
            secondaryMenuOpen = false;
        });

    }

    function toggleSecondaryMenu(menuOffset) {

        if ($(window).scrollTop() >= menuOffset) {
            $('body').toggleClass('fixed', true);
        } else {
            $('body').toggleClass('fixed', false);
        }
    }

    function updateSecondaryMenu() {
        $('.nav-scrollspy').on('activate.bs.scrollspy', function () {
            var currentItem = $('#in-page-navbar li.active > a');
            $('.current-active').empty().text(currentItem.text());
        });
    }

    function scrollSpy() {
        $('body').scrollspy({target: '.nav-scrollspy', offset: 160});
        updateSecondaryMenu();
    }

    function initSecondaryMenu() {

        if (!$secondaryMenuContainer.length) {
            return;
        }

        var menuOffset = $secondaryMenuContainer.offset().top - mainMenuHeight;

        toggleSecondaryMenu(menuOffset);

        scrollSpy();

        $(window).on('scroll touchstart touchmove touchend scrollend gesturechange', function () {
            toggleSecondaryMenu(menuOffset);
        });
    }

    function initGallery() {
        $('.lightgallery').lightGallery({
            useLeft: true,
            download: false
        });
    }

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                initHashLinks();
                initMainMenu();
                initSecondaryMenu();
                initGallery();
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        },
        'contact': {
            init: function () {

                $.getScript('https://maps.googleapis.com/maps/api/js', function () {

                    var mapCanvas = document.getElementById('map');
                    var latlng = new google.maps.LatLng(-37.145437, 146.445584);
                    var mapOptions = {
                        center: latlng,
                        zoom: 12,
                        mapTypeId: google.maps.MapTypeId.ROADMAP,
                        scrollwheel: false,
                        draggable: false,
                        styles: [{
                            stylers: [{
                                saturation: -100
                            }]
                        }],
                        scaleControl: false,
                        mapTypeControl: false,
                        streetViewControl: false,
                        rotateControl: false
                    };


                    var map = new google.maps.Map(mapCanvas, mapOptions);

                    var marker = new google.maps.Marker({
                        position: latlng,
                        map: map,
                        title: '195 New Summit Road'
                    });

                });
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
